@font-face {
  font-family: title-font;
  src: url("../public/tit-font.ttf");
}
@font-face {
  font-family: Rubik;
  src: url("../public/fonts/Rubik/Rubik-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: Rubik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;  
  justify-content: space-between;
}
header {
  position: absolute;
  top:0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  background: linear-gradient(to bottom, black 70%, transparent 90%);
  color: white;
  text-align: center;
  font-family: title-font;
  height: 80px;
  z-index: 1;
}
main {
  height: 95dvh;
  width: 100%;
  overflow: auto;
  font-family: Rubik;
  margin-top: 20px;
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  background: linear-gradient(to top, black 70%, transparent 90%);
}
img {
  object-fit: cover;
  height: 100%;
}
