@media (prefers-reduced-motion: no-preference) {
}

.logo {
  height: 50px;
}
.App-header {
  background: linear-gradient(to bottom, white 54%, transparent);
}
.App-header h1 {
  margin: 0;
  font-size: 1em;
  font-family: Rubik;
}
.navbar-toggler {
  position: absolute;
  right: 20px;
  top: 10px;
}
.list-of-services {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}
.list-of-services ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
.highlights {
  height: 500px;
  max-height: 40dvh;
  position: relative;
  width: 100%;
}
.carousel {
  position: relative;
}
.carousel-inner {
  height: 100%;
}
.carousel-item {
  height: 100%;
  z-index: -1;
}

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.637) 10%,
    white 30%
  );
}
.slide-wrapper {
  width: 90%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.slide-wrapper .items-list {
  position: relative;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  list-style: none;
  gap: 40px;
  max-width: 90%;
  height: 300px;
  padding: 5px;
}
.slide-wrapper .items-list .item {
  width: 300px;

  font-size: 0.9rem;
  height: 100%;
  min-width: 300px;
}
.slide-wrapper .items-list .item .card-title {
 
  overflow: hidden;
  text-overflow: ellipsis;
  display: "-webkit-box";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.slide-wrapper .items-list .item .card-text {
  -webkit-line-clamp: 3;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.slide-wrapper > button {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  background: white;
  border: none;
  padding: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.slide-wrapper #prevBtn {
  left: 0;
}
.slide-wrapper #nextBtn {
  right: 0;
}
.list-of-nav-links {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  max-width: 100dvw;

  height: fit-content;
}
.list-of-nav-links button:hover,
.list-of-nav-links a:hover {
  text-decoration: underline;
}
.offcanvas.offcanvas-end.show {
  font-weight: bold;
  background: linear-gradient(
    to right,
    white 0% 50%,
    green 50% 51%,
    white 52% 60%,
    yellow 60% 61%,
    white 62% 70%,
    red 70% 71%,
    white 80% 100%
  );
}
@media (max-width: 575px) {
  .highlights {
    height: unset;
  }
  .App-header h1 {
    font-size: 0.9em;
  }
  .App-header img {
  }
  .slide-wrapper .items-list {
    gap: 20px;
    height: 150px;
  }
  .slide-wrapper .items-list .item {
    width: 200px;
    font-size: 0.8rem;
    height: 100%;
    min-width: 200px;
  }
  .slide-wrapper .items-list .item .card-title {
    height: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: "-webkit-box";
    -webkit-line-clamp: 1;
    font-size: small;
    -webkit-box-orient: vertical;
  }
  .slide-wrapper .items-list .item .card-text {
    -webkit-line-clamp: 2;
  }
  #prevBtn,
  #nextBtn {
    display: none;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;

  background: linear-gradient(
    90deg,
    green 0% 30%,
    yellow 35% 60%,
    #ff0000 70% 100%
  );
}
