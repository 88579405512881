main{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
figure{
    width: 80%;
}
figure img{
    width: 100%;
   
}

article{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
    width: 90%;
    max-width: 900px;
    margin-top: 100px;
}
figcaption{
    text-align: center;
    font-weight:100;
    margin: 10px;
    font-style: italic;
    margin-top: 0;
}