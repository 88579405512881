.card{
    width: 100%;
    height: 100%;
    position: relative;
}
.card .card-body{
    height: 60%;
}
.card img{
    height: 40%;
    max-height: 300px;
}
.card.flag{    
    border-radius: 50%;
    overflow: hidden;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
}
.card.flag img{
    width: 100%;
    max-height: 500px;
    height: 60%;
    object-fit: cover;
}
.card.flag .card-body{
    height: 10%;
    background-color: transparent;
    text-align: center;
   
}